<template>
	<div v-loading="loading">
		<p class="m-right-title">
			<span class="u-flex-1">{{info.title || ''}}</span>
			<template v-if="!isOver">
			 
				<span style="color: red;margin-right: 140px;">{{getFormatTime(time)}}</span>
				<el-button size="mini" type="primary" class="m-title_btn" @click="submit"><i class="el-icon-tickets"></i>
交卷</el-button>
			</template>
		</p>
		<div class="m-con">
			<template v-if="!isOver">
				<p class="">
					<span class="m-title-label" v-if="currentData.type == 1">【单选题】</span>
					<span class="m-title-label" v-if="currentData.type == 2">【判断题】</span>
					<span class="m-title-label" v-if="currentData.type == 3">【多选题】</span>
					{{current + 1}}. {{currentData.title}}
				</p>

				<div class="m-con-img" v-if="currentData.image">
					<img :src="currentData.image" />
				</div>

				<div class="u-m-t-30">
					<template v-if="currentData.type == 1 || currentData.type == 2">
						<el-radio-group v-model="currentData.answerList">
							<div class="u-m-t-10 max-width" v-for="(item,index) in currentData.options">
								<el-radio :disabled="currentData.isAnswer" :label="item.key">{{item.key}} .
									{{item.value}}</el-radio>
							</div>
						</el-radio-group>
					</template>
					<template v-if="currentData.type == 3">
						<el-checkbox-group v-model="currentData.answerList">
							<div class="u-m-t-10 max-width" v-for="(item,index) in currentData.options">
								<el-checkbox :disabled="currentData.isAnswer" :label="item.key">{{item.key}} .
									{{item.value}}</el-checkbox>
							</div>
						</el-checkbox-group>
					</template>
					<!-- <div class="u-m-t-10">
					<el-checkbox :checked="false">B . 出票人不得在支票上记载自己是出票人</el-checkbox>
				</div>
				<div class="u-m-t-10">
					<el-checkbox :checked="false">C . 出票人不得在支票上记载自己是出票人</el-checkbox>
				</div>
				<div class="u-m-t-10">
					<el-checkbox :checked="false">D . 出票人不得在支票上记载自己是出票人</el-checkbox>
				</div> -->
				</div>

				<template v-if="currentData.isAnswer">
					<!--<p class="u-m-t-50">
						<span class="m-font1">【您的答案】</span>
						<span class="m-font2 u-m-l-4">{{formatArr(currentData.answerList)}}</span>
					</p> 
					 <p class="u-m-t-20">
						<span class="m-font1">【正确答案】</span>
						<span class="m-font1 u-m-l-4">{{formatArr(currentData.treuAnswer)}}</span>
					</p>
					<p class="u-m-t-20">
						<span class="m-font1">【答案解析】</span>
					</p>
					<div class="u-m-t-5 u-m-l-10 m-font1" v-html="currentData.analysis"></div> -->
				</template>
				<div class="u-flex u-row-between u-m-t-60">
					<div class="u-flex">
						<!-- <span class="m-btn" :class="{'m-dis':1 == current+1}" @click="beforeQuestion">上一题</span> -->
						<span class="m-btn u-m-l-16" v-if="count > current+1" :class="{'m-dis':count <= current+1}"
							@click="nextQuestion">下一题</span>
						<span class="m-btn u-m-l-16" v-else
							:class="{'m-dis':count <= current+1 && currentData.isAnswer}"
							@click="nextQuestion2">下一题</span>
					</div>
					<div class="u-flex" v-show="showBtn">
					
						<!-- <span class="m-btn2">答案</span>
					<span class="m-btn2 u-m-l-16" @click="collect">收藏</span> -->
					</div>
				</div>

				<div class="m-answer u-m-t-80">
					<div class="m-answer-list">
						<span class="m-answer-item" :class="[getResultClass(item,index)]" v-for="(item,index) in list">{{index + 1}}</span>
					</div>
					<!-- <div class="u-flex u-row-center u-col-center">
						<span class="m-btn3" @click="submit" v-if="isMaySubmit">交卷</span>
					</div> -->
				</div>
			</template>
			<template v-else>
				<h3 style="text-align: center;font-size: 30px;">{{chengji}}分</h3>
				<p style="color: #333;text-align: center;font-weight: 600;margin-top: 10px;">最终成绩</p>
				<p style="text-align: center;    padding-top: 30px;">	
				<span class="m-btn2" style="background: #1b87ef;
    padding: 10px 15px;
    color: #fff;
    margin: 0 auto;
    border: none;" @click="goLog">查看错题</span>
	</p>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		to_link
	} from "@/router/api"
	import {
		getExaminationsPaper,
		toTheTopic,
		theirPapers,
		collectQuestions
	} from "@/api/center.js"
	var oneFun = null
	export default {
		data() {
			return {
				showBtn: false,
				loading: false,
				isOver: false,
				chengji: 0,
				test_record_id: '',
				info: {},
				list: [],
				currentData: {},
				current: 0,
				count: 0,
				isMaySubmit: false,

				time: 0,
				timeFun: null,
				errRecord:[],//错误题

			}
		},
		mounted() {
			console.log(this.$route);
			this.getInfo()
			
		},
		computed:{
			  getResultClass:function(){
				  return (item,index)=>{
					  if(!item.isAnswer || item.result === ''){
						  return ''
					  }
					  let value = this.list[this.current]
					  return item.result == true ? 'isAnswered' : 'isAnsweredError';
					  // if(item.type == 3){
						 //  let value = this.list[this.current]
						 //  let res = value.answerList.some(val=>{
							//   return value.treuAnswer.indexOf(val) == -1
						 //  })
						 //  return res && value.answerList.length ==  value.treuAnswer.length ? 'isAnswered' : 'isAnsweredError'
					  // }else{
						 //  return item.answerList == item.treuAnswer ? 'isAnswered' : 'isAnsweredError'
					  // }
				  }
			  }
		},
		methods: {
			to_link,
			goLog(){
				window.location.href="/center/kaoshi_log?paper_id="+this.info.id;
			},
			collect() {
				collectQuestions({
					questions_id: this.currentData.id
				}).then(res => {

				})
			},
			formatArr(data) {
				if (Object.prototype.toString.call(data).slice(8, -1) == 'Array') {
					return data.join(',')
				} else {
					return data
				}
			},
			//提交试卷
			async submit() {
				if (oneFun == 'loading') return false;
				oneFun = 'loading'
				setTimeout(() => {
					oneFun = null
				}, 500)
				
				if(this.isMaySubmit==false){
					this.$message.error('您还未完成作答！');return false;
				}
				
				
				this.$confirm('是否确认交卷?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await theirPapers({
						test_record_id: this.test_record_id
					})
					if (res.code == 200) {
						this.$message.success(res.message)
						this.chengji = res.score
						setTimeout(() => {
							this.isOver = true
						}, 200)
					} else {
						this.$message.error(res.message)
					}
				}).catch(()=>{})

			},
			beforeQuestion() {
				if (this.current == 0) return false;
				this.current -= 1
				this.currentData = {}
				this.currentData = this.list[this.current]
			},
			//最后一道题
			nextQuestion2() {
				if (this.current + 1 >= this.count && this.currentData.isAnswer) return false;
				if (this.currentData.type == 3) {
					//多选
					if (this.currentData.answerList.length == 0) {
						this.$message.error('请选择选项')
						return false;
					}
				} else {
					//单选判断
					if (!this.currentData.answerList) {
						this.$message.error('请选择选项')
						return false;
					}
				}
				this.showBtn=true;
				this.checkQuestion()
			},
			//下一题
			nextQuestion() {
				if (this.current + 1 >= this.count) return false;

				if (oneFun == 'loading') return false;
				oneFun = 'loading'
				setTimeout(() => {
					oneFun = null
				}, 500)

				if (this.currentData.isAnswer) {
					this.current += 1
					this.currentData = {}
					this.currentData = this.list[this.current]
					return false
				}

				if (this.currentData.type == 3) {
					//多选
					if (this.currentData.answerList.length == 0) {
						this.$message.error('请选择选项')
						return false;
					}
				} else {
					//单选判断
					if (!this.currentData.answerList) {
						this.$message.error('请选择选项')
						return false;
					}
				}
				this.checkQuestion()
			},
			//判断题题目是否正确
			async checkQuestion() {
				let answer = this.currentData.type == 3 ? this.currentData.answerList.join(',') : this.currentData
					.answerList
				let params = {
					questions_id: this.currentData.id,
					answer: answer,
					test_record_id: this.test_record_id
				}
				// return false
				let res = await toTheTopic(params)
				if (res.code == 200) {
					let time_msg = this.current + 1 == this.count ? '' : ',3秒后进入下一题'
					
					if (res.is_true) {
						this.currentData['result'] = true
						//this.$message.success('回答正确')
						
					} else {
						//this.$message.error('回答错误')
						this.currentData['result'] = false;
						let errData={
							questions_id: this.currentData.id,
							answer: res.data.answer,
							err_answer: answer,
							test_record_id: this.test_record_id
						}
						
						
						this.errRecord.push(errData)
						
						let jsonData= JSON.stringify(this.errRecord);
						localStorage.setItem('errRecord', jsonData); 
						
					}
					
					this.currentData.analysis = res.data.analysis
					this.currentData.treuAnswer = res.data.answer
					this.currentData.isAnswer = true

					if (this.current + 1 < this.count) {
						//不是最后一道题的操作
						this.current += 1
						this.currentData = {}
						this.currentData = this.list[this.current]
						// setTimeout(()=>{
						// 	this.current += 1
						// 	this.currentData = {}
						// 	this.currentData = this.list[this.current]
						// },3000)
					} else {
						this.isMaySubmit = true
					}
				} else {
					this.$message.error(res.message)
				}
			},
			async getInfo() {
				this.loading = true
				let res = await getExaminationsPaper({
					paper_id: this.$route.query.paper_id
				})
				setTimeout(() => {
					this.loading = false
				}, 500)
				if (res.code == 200) {
					this.$alert('为了保证顺利考试，考试期间请勿刷新页面（导致答案丢失）', '注意事项', {
					          confirmButtonText: '确定'
							  })
					this.info = res.data.paper
					this.test_record_id = res.test_record_id
					let list = res.data.questions || []
					list.forEach(item => {
						item['isAnswer'] = false
						item['answerList'] = item.type == 3 ? [] : ''
						item['treuAnswer'] = item.type == 3 ? [] : ''
						item['analysis'] = ''
						item['result'] = ''
					})
					if (list.length > 0) {
						this.currentData = list[0]
					}
					this.count = list.length || 0
					this.list = list

					let time = (res.over_time - Number(new Date()) / 1000)
					this.time = time > 0 ? time : 0
					this.startTime()
				} else if (res.code === 0) {
					this.$router.back()
					this.$message.error(res.message)
				} else {
					this.$message.error(res.message)
				}
			},
      //剩余时间转换成倒计时
			getFormatTime(seconds) {
				let [hour, minute, second] = [0, 0, 0];
				// hour为给后面计算秒和分等用的(基于显示天的前提下计算)
				hour = Math.floor(seconds / (60 * 60));
				minute = Math.floor(seconds / 60) - hour * 60;
				second = Math.floor(seconds) - hour * 60 * 60 - minute * 60;

				hour = hour < 10 ? '0' + hour : hour;
				minute = minute < 10 ? '0' + minute : minute;
				second = second < 10 ? '0' + second : second;

				return `${hour}:${minute}:${second}`;
			},
			//开始计时
			startTime() {
				if (this.time == 0) return false;
				this.timeFun = null 
				this.timeFun = setInterval(() => {
					if (this.time <= 0) {
						clearInterval(this.timeFun)
						this.endTime()
						return false;
					}
					this.time -= 1
				}, 1000)
			},
			//结束
			endTime() {
				this.submit()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.max-width{
		
	}
	.m-font1 {
		font-size: 14px;
		color: #474747;
		line-height: 14px;
	}

	.m-font2 {
		font-size: 14px;
		color: #F8A310;
		line-height: 14px;
	}

	.m-con-img {
		width: 800px;
		margin-top: 15px;

		img {
			max-width: 800px;
			height: auto;
		}
	}
	.m-title_btn{
		position: absolute;
		right: 56px;
		top: 26px;
	}

	.m-title-label {
		color: red;
	}

	.m-right-title {
		display: flex;
		position: relative;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}

	.m-con {
		margin: 70px 44px;

		p {
			font-size: 16px;
			line-height: 24px;
			font-family: '宋体';
		}

		.m-con-font1 {
			font-size: 14px;
			color: #474747;
			line-height: 22px;
		}

		.m-btn {
			width: 110px;
			height: 33px;
			background: #FFFFFF;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-btn2 {
			width: 87px;
			height: 33px;
			background: #E2E2E2;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-dis {
			cursor: no-drop;
			opacity: 0.6;
		}

	}

	.m-answer {
		display: flex;
		width: 880px;
		padding: 24px 0 24px 24px;
		border: 1px solid #D2D2D2;

		.m-btn3 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 100px;
			background: #FF8D31;
			border-radius: 3px 3px 3px 3px;
			writing-mode: vertical-lr;
			writing-mode: tb-lr;
			letter-spacing: 10px;
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}

		.m-answer-list {
			display: flex;
			flex-wrap: wrap;
			width: 830px;

			.m-answer-item {
				width: 33px;
				height: 33px;
				background: #F1F1F1;
				border: 1px solid #D2D2D2;
				border-radius: 3px;
				font-size: 15px;
				color: #D6102A;
				text-align: center;
				line-height: 33px;
				cursor: pointer;
				margin-right: 6px;
				margin-bottom: 10px;
			}
			.isAnswered{
				  background: #42acdc;
				  color: #fff;
				  border: 0;
			}
			.isAnsweredError{
				  background: red;
				  color: #fff;
				  border: 0;
			}
		}
	}
</style>
<style lang="scss">
	.m-con {
		.el-radio, .el-radio__input{
			white-space:pre-wrap!important;
		}
		.el-radio__input.is-checked .el-radio__inner {
			border-color: #F8A310;
			background: #F8A310;
		}

		.el-radio__label {
			font-size: 16px;
			font-family: '宋体';
			line-height: 24px;
			color:#000;
		}

		.el-checkbox__label {
			font-size: 16px;
			font-family: '宋体';
			line-height: 24px;
			color:#000;
		}
		.el-radio__input.is-checked+.el-radio__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #F8A310;
			border-color: #F8A310;
		}
	}
</style>
